//import logo from './logo.svg';
import './App.css';
import FileUpload from './FileUpload';
//import PhotoList from './PhotoList';

function App() {
  return (
    <div className="App">
      <FileUpload/>
    </div>
  );
}

export default App;
