import React, { useState } from 'react';
import { useDropzone } from 'react-dropzone';

import AWS from 'aws-sdk';
import S3 from 'aws-sdk/clients/s3';

export default function FileUpload() {
  const uploadToS3 = async(file) => {
    const S3_BUCKET = "weddingphotos-omegacs";
    const REGION = "us-west-2";

    AWS.config.update({
      accessKeyId: "AKIAWUJKRPZRUMAOVVAY",
      secretAccessKey: "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",
    });

    const s3 = new S3({
      params: { Bucket: S3_BUCKET },
      region: REGION,
    });

    const params = {
      Bucket: S3_BUCKET,
      Key: Date.now() + file.name,
      Body: file,
    };

    try {
      const upload = await s3.putObject(params).promise();
      console.log(upload);
      alert("File uploaded!");
    } catch (error) {
      console.error(error);
      alert("Error uploading file " + error.message);
    }
  };

  const [ uploadedFiles, setUploadedFiles ] = useState([]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      setUploadedFiles(acceptedFiles);
      for ( const file of acceptedFiles) {
        uploadToS3(file);
      }
    },
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <p id="upload">Click here to upload photos or videos!</p>
      <ul>
        { uploadedFiles.map((file) => (
          <li key={file.name}>{file.name}</li>
        ))}
      </ul>
    </div>
  );
};
